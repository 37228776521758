import {
  AirlockBlockConfig,
  BlockCellId,
  BlockConfig,
  CellId,
  HexVersionId,
  SQLCellBlockConfig,
  assertNever,
  notEmpty,
} from "@hex/common";

import { hexVersionMPSelectors } from "../../../../redux/slices/hexVersionMPSlice.js";
import { RootState } from "../../../../redux/store.js";
import { getSortedCells } from "../../../../util/cellLayoutHelpers.js";

export function getOrderedChildCells({
  blockCellId,
  hexVersionId,
  parentCellId, // this is just the CellId of the block cell
  state,
}: {
  blockCellId: BlockCellId;
  hexVersionId: HexVersionId;
  parentCellId?: CellId;
  state: RootState;
}): CellId[] {
  // we are making a copy of the this array so that we can mutate it below,
  // since cellIds is immutable.
  const cellIds = [
    ...hexVersionMPSelectors
      .getBlockCellSelectors(hexVersionId)
      .getBlockChildCellIds(state, blockCellId),
  ];

  // If we pass in the parent ID then get that cell info as well
  if (parentCellId) {
    cellIds.push(parentCellId);
  }

  const cellsMap = hexVersionMPSelectors
    .getCellSelectors(hexVersionId)
    .selectEntities(state);

  const allCells = cellIds.map((cellId) => cellsMap?.[cellId]).filter(notEmpty);
  const sortedCells = getSortedCells(allCells);
  return sortedCells.map((cell) => cell.id);
}

export function getLastChildCellInBlock({
  blockCellId,
  hexVersionId,
  state,
}: {
  blockCellId: BlockCellId;
  hexVersionId: HexVersionId;
  state: RootState;
}): CellId | undefined {
  return getOrderedChildCells({ blockCellId, hexVersionId, state }).pop();
}

export function getPrimaryCellId(blockConfig: BlockConfig): CellId | null {
  if (SQLCellBlockConfig.guard(blockConfig)) {
    return blockConfig.sqlCellId;
  } else if (AirlockBlockConfig.guard(blockConfig)) {
    return null;
  } else {
    assertNever(blockConfig, blockConfig);
  }
}
